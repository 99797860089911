export const DASHBOARD_URL =
  process.env.NEXT_PUBLIC_DASHBOARD_URL || "https://app.coverdash.com"
export const QUOTES_URL =
  process.env.NEXT_PUBLIC_QUOTES_URL || "https://quotes.coverdash.com"
export const CRUD_SERVICE_URL = process.env.NEXT_PUBLIC_CRUD_SERVICE_URL
export const APP_SERVICE_URL = process.env.NEXT_PUBLIC_APP_SERVICE_URL

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}
export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
/**
 * Framer Motion variation variables.
 * Each one is to be used when animating an element.
 */

// Variant for parent when staggering children elements
export const staggerParentVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.25,
      staggerChildren: 0.25,
      delayChildren: 0.2,
    },
  },
}

// Variant to be used on children of `parentContainer` (staggered child element)
export const staggerChildVariants = {
  hidden: { opacity: 0, translateY: 25 },
  visible: {
    opacity: 1,
    translateY: 0,
  },
}

// Default variant
export const defaultVariants = {
  hidden: { opacity: 0, translateY: 25 },
  visible: {
    opacity: 1,
    translateY: 0,
    transition: {
      delay: 0.25,
    },
  },
}

// Get CRUD Service Token
export const getToken = async (): Promise<string> => {
  try {
    const tokenResponse = await fetch(`${APP_SERVICE_URL}/crud-service/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "client_credentials",
        source: "Coverdash Website",
      }),
    })

    const { access_token }: { access_token: string } =
      await tokenResponse.json()

    return access_token
  } catch (err: unknown) {
    throw new Error(err as string)
  }
}
