import React from 'react';

import { LazyMotion, domAnimation, m } from 'framer-motion';

export function FadeIn({
  children,
  delay,
  ...rest
}: {
  children: React.ReactNode;
  delay?: number;
  [rest: string]: unknown;
}) {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        key={Math.random()}
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
          amount: 0.4
        }}
        variants={{
          hidden: { opacity: 0, translateY: 25 },
          visible: {
            opacity: 1,
            translateY: 0,
            transition: {
              type: 'spring',
              delay
            }
          }
        }}
        {...rest}
      >
        {children}
      </m.div>
    </LazyMotion>
  );
}
