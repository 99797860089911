/* eslint-disable @typescript-eslint/no-explicit-any */
import { License } from "../lib/models"
import { getLicense } from "../service"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"

interface Props {
  children: React.ReactNode
}

interface Context {
  license: License | undefined
  setLicense: (license: License) => void
}

const GoogleTranslateContext = createContext<Context>(undefined as any)

export function GoogleTranslateProvider({ children }: Props) {
  const [license, setLicense] = useState<License>()

  useEffect(() => {
    const licenseId = localStorage.getItem("licenseId")
    if (licenseId) {
      getLicense(licenseId)
        .then((license) => {
          setLicense(license)
        })
        .catch((error) => {
          console.error(
            "GoogleTranslateContext ~ Error fetching license:",
            error
          )
        })
    } else {
      setLicense(undefined)
    }
  }, [])

  const googleTranslateElementInit = useCallback(() => {
    new (window as any).google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    )
  }, [])

  const addGoogleTranslateScript = useMemo(() => {
    return () => {
      if (
        !document.querySelector(
          "script[src='//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit']"
        )
      ) {
        const addScript = document.createElement("script")
        addScript.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        )
        document.body.appendChild(addScript)
        ;(window as any).googleTranslateElementInit = googleTranslateElementInit
        if (license?.partnerPreferences?.defaultLanguage) {
          document.cookie = `googtrans=/en/${license.partnerPreferences.defaultLanguage}`
        }
        document.documentElement.style.marginTop = "-2.4rem"
      }
    }
  }, [googleTranslateElementInit, license?.partnerPreferences?.defaultLanguage])

  useEffect(() => {
    if (license?.partnerPreferences?.googleTranslateEnabled) {
      try {
        addGoogleTranslateScript()
      } catch (error) {
        console.log(error)
      }
    }
  }, [license, addGoogleTranslateScript])

  const value: Context = {
    license,
    setLicense,
  }

  return (
    <GoogleTranslateContext.Provider value={value}>
      <div id="google_translate_element"></div>
      {children}
    </GoogleTranslateContext.Provider>
  )
}

export function useGoogleTranslateContext() {
  return useContext(GoogleTranslateContext)
}
