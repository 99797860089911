/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPartnerWebpage, License } from "./lib/models"
import { BusinessClassification } from "./types"

export const CRUD_SERVICE_URL =
  process.env.NEXT_PUBLIC_CRUD_SERVICE_URL ?? process.env.CRUD_SERVICE_URL

export async function fetchCrudService(
  method: "GET" | "POST" | "PATCH" | "DELETE",
  body: any = null,
  path: string
): Promise<any> {
  // dev_log(`⏳ [${method}] CS/${path}`, "(body)", body)
  let headers: any = {}
  if (["POST", "PATCH"].includes(method)) {
    headers = {
      ...headers,
      "Content-Type": "application/json",
    }
  }

  const res = await fetch(`${CRUD_SERVICE_URL}/${path}`, {
    method,
    headers,
    body: body ? JSON.stringify(body) : null,
  })

  // console.log("res.status:", res.status)
  if (res.status > 400) {
    // throw new Error("Bad Request")
    const json = { status: res.status, error: "Bad Request" }

    // const emojiForResponse = "❌"
    // dev_log(`${emojiForResponse} [${method}] QE/${path}`, "(response) ", json)
    return json
  }
  //
  else {
    const json = await res.json()

    // const emojiForResponse = json.error || json.Error ? "❌" : "✅"
    // dev_log(`${emojiForResponse} [${method}] CS/${path}`, "(response) ", json)
    return json
  }
}

export async function getLicense(licenseId: string) {
  const res = await fetchCrudService("GET", null, `public/license/${licenseId}`)
  const license: License = res.License
  return license
}

export async function getBusinessClassificationListBySearch(
  policyType: string,
  query: string
) {
  const res = await fetchCrudService(
    "POST",
    {
      policyType,
      query,
    },
    `public/industryCodes`
  )

  const businessClassificationList: {
    recommended: BusinessClassification[]
    rest: BusinessClassification[]
  } = res
  return businessClassificationList
}

export async function getPartnerWebpage(partnerWebpageId: string) {
  const res = await fetchCrudService(
    "GET",
    null,
    `public/partnerWebpage/${partnerWebpageId}`
  )
  return res.PartnerWebpage as IPartnerWebpage
}
