import React from "react"

// ee061a41-c489-449b-9688-1954a6544d0e
const Subscribe = () => {
  const [email, setEmail] = React.useState("")
  const [sending, setSending] = React.useState(false)
  const [message, setMessage] = React.useState("")

  async function handleSubmit() {
    setSending(true)
    if (email.length === 0) {
      setMessage("Please enter a valid email address.")
    }
    //
    else {
      setMessage("")
      const listId = `f9c9cb4a-8b9c-11ed-a106-2bd5dff73c66`
      const res = await fetch(`/api/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ listId, email }),
      })

      const data = await res.json()
      console.log(data)
      if (res.status === 200) {
        setSending(false)
        setEmail("")
        setMessage(`Thanks, you'll be hearing from us soon!`)
      }
      //
      else {
        setSending(false)
        setMessage(data.error?.message || "Something went wrong.")
      }
    }
  }

  return (
    <div className="sm:max-w-xl sm:mx-auto lg:mx-0">
      <div className="sm:flex">
        <div className="min-w-0 flex-1">
          <label htmlFor="email" className="sr-only">
            Email address
          </label>

          <input
            id="email-input"
            name="email"
            type="email"
            placeholder="you@email.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full px-4 py-3 rounded-md border border-gray-300 text-base text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8CE2A4]"
          />
          <p className="text-white mt-1 font-medium">{message}</p>
          {sending && <p className="text-white mt-1 font-medium">Sending...</p>}
        </div>
        <div className="mt-3 sm:mt-0 sm:ml-3">
          <button
            onClick={handleSubmit}
            className="btn-secondary py-[13px] w-full sm:w-32"
            //   className="cursor:pointer block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r
            // from-primary to-primary text-white font-medium
            // hover:from-primary hover:to-[#27AE60] focus:outline-none
            // focus:ring-2 focus:ring-offset-2 focus:ring-[#8CE2A4]"
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  )
}

export default Subscribe
