/* eslint-disable @next/next/no-html-link-for-pages */

/* eslint-disable react/no-unescaped-entities */
import Subscribe from "./EOSubscribe"
import EnterPasswordDialog from "./EnterPasswordDialog"
import { FadeIn } from "./FadeIn"
import { ArrowRightIcon } from "@heroicons/react/outline"
import Image from "next/image"
import * as React from "react"

export default function BetaAuthenticate() {
  const [openPasswordDialog, setOpenPasswordDialog] = React.useState(false)
  return (
    <div className="bg-neutral-100 cdHeading">
      <EnterPasswordDialog
        open={openPasswordDialog}
        setOpen={setOpenPasswordDialog}
        title="Enter password to gain access"
      />
      <div className="mx-auto max-w-7xl w-full pt-20 text-center lg:pt-32">
        <div className="mx-auto px-8 lg:w-3/4 lg:px-0">
          <FadeIn>
            <div className="hidden md:inline-flex w-full max-w-[280px] mx-auto h-12 relative">
              <Image
                src="/coverdash_logo_new_light_transparent_shield.png"
                alt="Coverdash logo"
                width={842.5}
                height={143.5}
              />
            </div>
          </FadeIn>
          <FadeIn delay={0.3}>
            <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-alt md:text-6xl">
              Business insurance in a{" "}
              <span className="text-primary">dash.</span>
            </h1>
          </FadeIn>
          <FadeIn delay={0.5}>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Tailored solutions. Instant coverage. Big savings.
            </p>
          </FadeIn>
          <FadeIn delay={0.5}>
            <p className="mt-3 max-w-md mx-auto font-semibold text-alt text-2xl md:mt-5 md:max-w-3xl">
              We're live! Click below to gain access:
            </p>
          </FadeIn>
          <div className="mt-4 sm:flex justify-center">
            <FadeIn delay={0.55}>
              <div className="rounded-md shadow">
                <button
                  onClick={() => setOpenPasswordDialog(true)}
                  className="w-full md:w-auto btn-primary font-medium"
                >
                  <span>Access now</span>
                  <ArrowRightIcon className="h-4 w-4 inline" />
                </button>
              </div>
            </FadeIn>
          </div>
        </div>
      </div>
      <div className="mt-16">
        <img
          src="/img/hero.jpg"
          alt="Illustration of people in office space"
          className="object-cover w-full h-full hidden lg:block"
        />
        <div className="h-full lg:hidden">
          <Image
            height={756}
            width={1170}
            src="/img/hero-mobile.png"
            alt="Illustration of people in office space"
          />
        </div>
      </div>

      <div className="relative sm:py-16 bg-white">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative overflow-hidden rounded-2xl bg-primary px-6 py-10 shadow-xl sm:px-12 sm:py-20">
            <div
              aria-hidden="true"
              className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
            >
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-green-300 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-green-300 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
                  Sign up
                </h2>
                <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-50">
                  Enter your email below to request access:
                </p>
              </div>
              {/* <form action="#" className="mt-12 sm:mx-auto sm:flex sm:max-w-lg">
                <div className="min-w-0 flex-1">
                  <label htmlFor="cta-email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="cta-email"
                    type="email"
                    className="block w-full rounded-md border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-400 shadow-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-alt shadow hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-rose-500 sm:px-10"
                  >
                    Subscribe
                  </button>
                </div>
              </form> */}
              <div className="mt-12 sm:mx-auto sm:max-w-lg">
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// export default function BetaAuthenticate() {
//   return (
//     <div style={{ backgroundColor: '#141e25' }} className="h-screen">
//       <div className="relative overflow-hidden">
//         <main style={{ backgroundColor: '#141e25' }}>
//           <div
//             style={{ backgroundColor: '#141e25' }}
//             className="pt-10 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden"
//           >
//             <div className="mx-auto max-w-7xl lg:px-8">
//               <div className="lg:grid lg:grid-cols-2 lg:gap-8">
//                 <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
//                   <div className="lg:py-24">
//                     {/* <a
//                       href="#"
//                       className="inline-flex items-center text-white rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
//                     >
//                       <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-primary to-cdgreen-600 rounded-full">
//                         Coverage in minutes
//                       </span>
//                     </a> */}
//                     <a
//                       target="_blank"
//                       href="https://careers.coverdash.com"
//                       className="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
//                       rel="noreferrer"
//                     >
//                       <span className="rounded-full bg-primary px-3 py-0.5 text-sm font-semibold leading-5 text-white">
//                         We're hiring
//                       </span>
//                       <span className="ml-4 text-sm">
//                         Visit our careers page
//                       </span>
//                       <ChevronRightIcon
//                         className="ml-2 h-5 w-5 text-gray-500"
//                         aria-hidden="true"
//                       />
//                     </a>
//                     <div className="mt-2">
//                       <span className="-ml-1 px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide">
//                         Coverage in minutes
//                       </span>
//                     </div>
//                     <h1 className="z-10 mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
//                       Business insurance in a{' '}
//                       <span className="pb-3 bg-clip-text text-transparent bg-gradient-to-r from-primary to-[#8CE2A4] sm:pb-5">
//                         dash.
//                       </span>
//                     </h1>
//                     <p className="text-xl text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
//                       Tailored Solutions. Instant Coverage. Big Savings.
//                     </p>

//                     <div className="mt-10 sm:mt-12">
//                       <p className="text-base text-gray-300 mb-2">
//                         We're in beta! Enter password:
//                       </p>
//                       <form
//                         className="sm:max-w-xl sm:mx-auto lg:mx-0 mb-4"
//                         action="/"
//                         onSubmit={(e: React.SyntheticEvent) => {
//                           const target = e.target as typeof e.target & {
//                             password: { value: string };
//                           };
//                           const password = target.password.value;

//                           if (password === 'coverdashStaging2022!') {
//                             localStorage.setItem(
//                               'isOwnerAuthenticated',
//                               'true'
//                             );
//                           }
//                         }}
//                       >
//                         <div className="sm:flex">
//                           <div className="min-w-0 flex-1">
//                             <input
//                               id="password-input"
//                               name="password"
//                               type="text"
//                               placeholder="Enter the password"
//                               className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8CE2A4] focus:ring-offset-gray-900"
//                             />
//                           </div>
//                           <div className="mt-3 sm:mt-0 sm:ml-3">
//                             <button
//                               type="submit"
//                               className="
//                               cursor:pointer block w-full py-3 px-7
//                               rounded-md shadow bg-white text-gray-700 font-bold focus:outline-none"
//                             >
//                               Submit
//                             </button>
//                           </div>
//                         </div>
//                       </form>
//                       <p className="text-base text-gray-300 mb-2">
//                         Or sign up below to get access:
//                       </p>
//                       <Subscribe />
//                       {/* <form
//                         action="#"
//                         className="sm:max-w-xl sm:mx-auto lg:mx-0"
//                       >
//                         <div className="sm:flex">
//                           <div className="min-w-0 flex-1">
//                             <label htmlFor="email" className="sr-only">
//                               Email address
//                             </label>

//                             <input
//                               id="email"
//                               type="email"
//                               placeholder="Enter your email"
//                               className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8CE2A4] focus:ring-offset-gray-900"
//                             />
//                           </div>
//                           <div className="mt-3 sm:mt-0 sm:ml-3">
//                             <button
//                               type="submit"
//                               className="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-primary to-cdgreen-600 text-white font-medium hover:from-cdgreen-600 hover:to-cdgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#8CE2A4] focus:ring-offset-gray-900"
//                             >
//                               Subscribe
//                             </button>
//                           </div>
//                         </div>
//                       </form> */}
//                     </div>
//                   </div>
//                 </div>
//                 <div className="my-auto">
//                   <div className="w-2/3 lg:w-full mt-12 mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
//                     {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
//                     <img
//                       // className="w-full lg:absolute lg:bottom-0 lg:inset-y-0 lg:left-0 lg:h-20 lg:w-auto lg:max-w-none"
//                       src="/coverdash_logo_new_light_transparent_shield.png"
//                       alt=""
//                     ></img>
//                   </div>
//                   <p className="text-white mt-8 text-center text-xs sm:text-base">
//                     Freelancers | E-commerce Merchants | Small Business Owners
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//           <br />
//         </main>
//         <div className="hidden sm:block">
//           {/* <svg
//             className="fixed -top-32 -mr-3 lg:m-0 lg:right-0"
//             width={304}
//             height={200}
//             fill="none"
//             viewBox="0 0 404 392"
//           >
//             <defs>
//               <pattern
//                 id="837c3e70-6c3a-44e6-8854-cc48c737b659"
//                 x={0}
//                 y={0}
//                 width={20}
//                 height={20}
//                 patternUnits="userSpaceOnUse"
//               >
//                 <rect
//                   x={0}
//                   y={0}
//                   width={4}
//                   height={4}
//                   className="text-cdgreen-300"
//                   fill="currentColor"
//                 />
//               </pattern>
//             </defs>
//             <rect
//               width={404}
//               height={392}
//               fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
//             />
//           </svg> */}
//         </div>
//       </div>
//     </div>
//   );
// }
