import { Dialog, Transition } from "@headlessui/react"
import { InformationCircleIcon } from "@heroicons/react/outline"
import * as React from "react"
import { Fragment, useRef } from "react"

const SITE_PASSWORD = process.env.NEXT_PUBLIC_SITE_PASSWORD

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  showIcon?: boolean
}
export default function EnterPasswordDialog({
  open,
  setOpen,
  title,
  showIcon,
}: Props) {
  const closeButtonRef = useRef(null)
  const [password, setPassword] = React.useState("")
  const [wrongPassword, setWrongPassword] = React.useState(false)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 cdBody"
        initialFocus={closeButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`
          flex sm:min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0
          `}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  {showIcon && (
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <InformationCircleIcon
                        className="h-6 w-6 text-blue-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-6">
                      <form
                        className="sm:max-w-xl sm:mx-auto lg:mx-0 mb-4"
                        action="/"
                        onSubmit={(e: React.SyntheticEvent) => {
                          const target = e.target as typeof e.target & {
                            password: { value: string }
                          }
                          const password = target.password.value

                          if (password === SITE_PASSWORD) {
                            setWrongPassword(false)
                            localStorage.setItem("isOwnerAuthenticated", "true")
                          }
                          //
                          else {
                            e.preventDefault()
                            setWrongPassword(true)
                          }
                        }}
                      >
                        <div className="sm:flex">
                          <div className="min-w-0 flex-1">
                            <input
                              id="password-input"
                              name="password"
                              type="password"
                              placeholder="Enter the password"
                              className="block w-full px-4 py-3 rounded-md text-base text-gray-900 placeholder-gray-400 border border-gray-300 focus:border-0 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary focus:ring-offset-primary"
                              value={password}
                              onChange={(e) =>
                                setPassword(e.target.value.toLowerCase())
                              }
                            />
                          </div>
                          <div className="mt-3 sm:mt-0 sm:ml-3">
                            <button
                              type="submit"
                              className="btn-primary py-[13px] w-full sm:w-auto"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        {wrongPassword && (
                          <div className="mt-1 text-sm text-left">
                            Sorry! Please sign up to request access.
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white 
                    px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none 
                    focus:ring-0 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={closeButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
